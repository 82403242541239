import http_common from './http-common'


/* /api/user/role/{role}/status/{status} */
/* /api/user/status */

const getUserList =(data)=> {
  return  http_common.get(`/user/role/${data.role}/status/${data.status} `);
};


const updateUser =(data)=> {
    return  http_common.put(`/user/status`, data);
};

const registerAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/registration`, data);
}

const editAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/edit`, data);
}

const getAccessList=()=>{
  return  http_common.get(`/condo/accessCard/list`);
}

const deleteAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/delete`, data);
}

const addAccessCard=(data)=>{
  return  http_common.post(`/condo/accessCard/add`, data);
}

export default {
    getUserList, updateUser, registerAccessCard, getAccessList,
    deleteAccessCard, addAccessCard, editAccessCard
}
