import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SuccessToast = ({ open, handleClose, message }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      color='primary'
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      //message={message}
      action={
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <CheckCircleOutline />
        </IconButton>
      }
    >
        <Alert severity="primary">{message}</Alert>
    </Snackbar>
  );
};

export default SuccessToast;
