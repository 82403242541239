import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom"; 
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Logo from '../Assets/Logo.png';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import colorConfigs from '../configs/colorConfigs';
import Link from '@mui/material/Link';

const validation={
    emailId: false,
    password: false,
}
  
function Login(){
    const [valid, setValid]  = useState(validation);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const navigate = useNavigate();

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();   
        const data = new FormData(event.currentTarget);
        let value = {
        emailId: data.get('email'),
        password: data.get('password'),
        }
        console.log("value", value)
        if(value.password == '360CloudSecure' && value.emailId == "360CloudSecure@gmail.com"){
            navigate("/dashboard");
        } 
    };

    return(
        <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor: colorConfigs.sidebar.bg, height: `100vh`}}>
            <Grid item>
                <img src={Logo} width={`100%`} height={80}/>
                <Card sx={{ maxWidth: 500}}>
                    <CardContent>
                        <Box
                            sx={{
                                marginTop: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            >
                               
                              
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                defaultValue={"360CloudSecure@gmail.com"}
                                autoFocus
                                />
                                <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                defaultValue={"360CloudSecure"}
                                autoComplete="current-password"
                                />
                                
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                >
                                Sign In
                                </Button>
                                {/* <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2" color="secondary">
                                        Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2" color="secondary">
                                        {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid> */}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
       </Grid>
    );
}


export default Login;