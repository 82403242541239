import axios from "axios";


export default axios.create({
  baseURL: 'https://mightyi-condo.wegosecure.com/api/',
  //baseURL: 'https://e3fb-2405-201-e013-c916-6875-a915-2702-5ab1.in.ngrok.io/api/v1/',
  headers: {
    'Content-type': 'application/json',
    'Authorization': 'Basic bWlnaHR5aWFkbWluOmNvbmRvQDEyMw=='
  },
});
