import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: colors.grey[900],
    color: "#fff",
    hoverBg: '#DF2626',
    activeBg: '#DF2626',
  },
  colorCode: {
    primary: `#DF2626`,
    secondary: "#C7C7C7",
    tertiery:  "#2D2D2D",
  },
  topbar: {
    bg: "#2D2D2D",
    color: "#fff"
  },
  mainBg: `#2D2D2D`,
};

export default colorConfigs;