import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HomeIcon from '@mui/icons-material/Home';
import AddCardIcon from '@mui/icons-material/AddCard';
import Dashboard from '../Pages/Dashboard';
import AccessCardRegistration from '../Pages/AccessCardRegistration';


const appRoutes = [
   /*  {
        index: true,
        element: <Dashboard />,
        state: "dashboard"
    }, */
    {
        path: "/dashboard",
        element: <Dashboard />,
        state: "dashboard",
        sidebarProps: {
          displayText: "User Approval",
          icon: <DashboardOutlinedIcon />
        }
    },

    {
      path: "/access-card-registration",
      element: <AccessCardRegistration />,
      state: "AccessCardRegistration",
      sidebarProps: {
        displayText: "Access Card Registration",
        icon: <AddCardIcon />
      }
  },
    
];

export default appRoutes;